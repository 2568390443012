import React from 'react';
import vCardsJS from "vcards-js";
import email from'../images/mai.png'
import phone from '../images/tel.png'
import globe from '../images/glo.png'
import user from '../images/add-user (2).png'
import swiss from '../images/switzerland (3).png'
import germany from '../images/germany.png'
import usa from '../images/usa.png'

function Johni(){
  <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>

  var vCard = vCardsJS();

  //set properties
  vCard.firstName = "John";
  vCard.lastName = "Shnaidman";
  vCard.organization = "Luxor Holding";
  vCard.workPhone = "+1 416 854 66 56";
  vCard.title = "Founder";
  vCard.email="js@luxora-holding.com";
  vCard.url = "www.luxora-holding.com";



  //get as formatted string
  console.log(vCard.getFormattedString());
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([vCard.getFormattedString()], {
      type: "text/vcard"
    });
    element.href = URL.createObjectURL(file);
    element.download = "johnshnaidman.vcf";
    document.body.appendChild(element);
    element.click();
  };
    return (
      <>
  

  <div>
<div class="tw-uses fit" id="more">
                <div class="tw-img">
                    <div class="card-w card-d card-l">
                        <div class="header-a header-d">
                            <div class="alt luxor">
                             
                                    <h1 class="title-w besimz candy">Mr. John Shnaidman</h1>
                                    <p className='delfi candy'>Founder</p>
                            </div>                         
                        </div>
                      

                        <div class="div-bes div-lux div-f">
                       
                        <div class="info-b info-l  ">
                            <div class="inf">
                       </div>
                           <div class="inf"><img src={phone}/><a href="tel:+1418546656">+1 416 854 66 56</a></div>
                            </div>
                        </div>
                        <div class="div-bes div-lux">
                       
                        <div class="info-b info-l">
                            
                                <div class="inf">
                               <img src={email} /> <a href="mailto:js@luxora-holding.com">js@luxora-holding.com</a>
                            </div> </div>
                           
                        </div>
                        <div class="div-bes div-lux">
                       
                        <div class="info-b info-l">
                               
                                <div class="inf">
                                <img src={globe} /><a href="https://luxora-holding.com/">www.luxora-holding.com</a>
                            </div></div>
                            
                        </div>
                        <div class="div-bes div-lux fund">
                       
                       <div class="info-b info-l ">
                           
                       <div class="inf alo">
                               <img src={swiss} /> <a href="https://www.google.com/maps/place/Winkelweg+3,+8853+Lachen,+Switzerland/@47.1932008,8.8509879,17z/data=!3m1!4b1!4m6!3m5!1s0x479ab60d231db837:0x4b8766560c95230d!8m2!3d47.1931972!4d8.8535628!16s%2Fg%2F11c2d56zb0?entry=ttu">Winkelweg 3, 8853 Lachen</a>
                           </div>
                            
                           <div class="inf alo">
                               <img src={germany} /> <a href="https://www.google.com/maps/place/Am+Klein+Flottbeker+bhf+11,+22609+Hamburg,+Germany/@53.5578887,9.8592071,17z/data=!3m1!4b1!4m6!3m5!1s0x47b1845b31177669:0xdbeed44d26564147!8m2!3d53.5578855!4d9.861782!16s%2Fg%2F11h6lwm9s7?entry=ttu">Am klein Flottbeker Bahnhof 11, 22609 Hamburg</a>
                           </div>
                            
                           <div class="inf alo">
                               <img src={usa} /> <a href="https://www.google.com/maps/place/18305+Biscayne+Blvd,+Aventura,+FL+33160,+USA/@25.9455274,-80.1493482,17z/data=!3m1!4b1!4m6!3m5!1s0x88d9ac5440ebeae9:0x4dc08e317e5a6461!8m2!3d25.9455226!4d-80.1467733!16s%2Fg%2F11bw3wznl1?entry=ttu">18305 Biscayne Blvd #200, Aventura, FL 33160</a>
                           </div>

                           
                           
                           </div>
      
                           <div class="inf useri userluc">
                           <button onClick={downloadTxtFile}><img src={user} className='icons'/></button> 
                           </div>
                        
                       </div>
                       <div class="div fund copy">
                       
                       <div className="copyrighti">
                               <p className='copyright'>© Copyright 2023. <a href="https://dolphinstech.eu/nfcdeutsch.html">Dolphins Group</a></p>
                              

                           
                           
                           </div>
      
                           
                        
                       </div>
                  
                        
                    </div>
                </div>
                </div>
                </div>


</>

  );
}

export default Johni;