import React from 'react';
import vCardsJS from "vcards-js";
import b from '../images/logo-1.png'

function Naim(){
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>
  
    var vCard = vCardsJS();
  
    //set properties
    vCard.firstName = "Naim";
    vCard.lastName = "Zukaj";
    vCard.organization = "Kompania e Sigurimeve Dukagjini";
    vCard.workPhone = "+383 44 626 999";
    vCard.title = "Agjent i shitjes";
    vCard.email="naim_zukaj@hotmail.com";
    vCard.url = "http://dukagjiniks.com/";
  
  
    //get as formatted string
    console.log(vCard.getFormattedString());
    const downloadTxtFile = () => {
      const element = document.createElement("a");
      const file = new Blob([vCard.getFormattedString()], {
        type: "text/vcard"
      });
      element.href = URL.createObjectURL(file);
      element.download = "naimzukaj.vcf";
      document.body.appendChild(element);
      element.click();
    };
      return (
        <>
<div>
<div class="tw-uses" id="more">
                <div class="tw-img">
                    <div class="card-w">
                        <div class="header-w">
                            <div class="shape-w">
                                <img src={b} alt=""/>
                                    <h1 class="title-w">Naim Zukaj</h1>
                            </div>
                            <div class="shape-two-w"></div>
                        </div>

                        <div class="text-w">
                            <p class="work-w"><span className='w'>Agjent i shitjes</span></p>
                           
                        </div>
                        <div class="div-w">
                            <div class="info-w">
                                <i class="fas fa-phone-alt p-w"></i>
                                <a href="tel:+38344626999">+383 44 626 999</a>
                            </div>
                            <div class="info-w">

                                <i class="fas fa-envelope p-w"></i>
                                <a href="mailto:naim_zukaj@hotmail.com">naim_zukaj@hotmail.com</a>
                            </div>
                            <div class="info-w">

                                <i class="fas fa-globe p-w"></i>
                                <a href="http://dukagjiniks.com/">www.dukagjiniks.com</a>
                            </div>
<div class="info-w">
<i class="fa fa-facebook p-w"></i>
<a href="https://www.facebook.com/dukagjinks">Kompania e Sigurimeve Dukagjini</a>
</div>
                        </div>
                  
                        <button class="save-w" onClick={downloadTxtFile}>
                            Save as contact
                            <i class="fas fa-download ml-w"></i>
                        </button>
                    </div>
                </div>
                </div>
                </div>


</>

);
}

export default Naim;
