import React from 'react';
import vCardsJS from "vcards-js";
import b from '../images/lir2.png'
import c from '../images/instagram (5).png'
import d from '../images/facebook (5).png'
import e from '../images/whatsapp (1).png'
import f from '../images/viber (2).png'
import email from'../images/mail (2).png'
import phone from '../images/call (2).png'
import globe from '../images/worldwide.png'
import user from '../images/add-user (2).png'



function Arjanit(){
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>
  
    var vCard = vCardsJS();
  
    //set properties
    vCard.firstName = "Arjanit";
    vCard.lastName = "Zukaj";
    vCard.role="Arkitekt"
    vCard.organization = "Liridoni Construction";
    vCard.workPhone = "+383 49 703 335";
    vCard.email="arjanitzukaj1@gmail.com";
  
  
  
    //get as formatted string
    console.log(vCard.getFormattedString());
    const downloadTxtFile = () => {
      const element = document.createElement("a");
      const file = new Blob([vCard.getFormattedString()], {
        type: "text/vcard"
      });
      element.href = URL.createObjectURL(file);
      element.download = "arjanitzukaj.vcf";
      document.body.appendChild(element);
      element.click();
    };
      return (
        <>
<div>
<div class="card" id="more">
<div class="bg-two b-liri">

            <br/>
          
            <img src={b} class="dive"/>
        </div>
        

        <div class="text perArch">
        
        <p className='slogi'></p>
            <h1 class="titles">Arjanit Zukaj</h1>
                <p class="ceo-arch">Arkitekt</p>
          
        </div>
     

     <div></div>
        <div class="div-bes div-liri">
                       
                       <div class="info-b info-l">
                           <div class="inf ">
                          <p>Mobile</p></div>
                          <div class="inf inf-l"><img src={phone} className='icons'/><a href="tel:+38349703335">+383 49 703 335</a></div>
                           </div>
                       
                           
                       </div>
                       <div class="div-bes div-liri">
                      
                       <div class="info-b info-l">
                               <p>Email</p>
                               <div class="inf inf-l">
                              <img src={email} className='icons'/> <a href="mailto:arjanitzukaj1@gmail.com">arjanitzukaj1@gmail.com</a>
                           </div> </div>
                          
                       </div>
                       {/* <div class="div-bes div-liri">
                      
                       <div class="info-b info-l">
                               <p>Website</p>
                               <div class="inf inf-l">
                               <img src={globe} className='icons'/><a href="https://dolphinsgroup.eu/">www.liridoni-kos.com</a>
                           </div></div>
                          
                       </div> */}
                       <div class="div-bes fundi div-liri ">
                      
                       <div class="info-b info-l ">
                               <p>Website</p>
                               <div class="inf inf-l">
                               <img src={globe} className='icons'/><a href="www.lc-kos.com">www.lc-kos.com</a>
                           </div></div>
                          <div class="inf inf-l useri useri-lir">
                          <button onClick={downloadTxtFile}><img src={user} className='icons'/></button> 
                          </div>
                       
                      </div>

                      <div class="liri-soca">
                      
                     
                             
                              
                              <div className='dolpsoci'>
                            <a href="https://instagram.com/liridoniconstruction?igshid=MDM4ZDc5MmU=" className='socico'>
                            <img src={c} className="icolit" /></a>
                            <a href="https://www.facebook.com/liridonicon" className='socico'>
                            <img src={d}  className="icolit"/></a>
                            <a href="https://wa.me/38349703335" className='socico'>
                            <img src={e} className="icolit" /></a>
                            <a href="viber://chat/?number=%2B38349703335"className='socico'>
                            <img src={f} className="icolit" /></a>
                   
                          </div> 
                         
                      </div>
                 
                       
                   </div>
                   </div>


</>

);
}

export default Arjanit;
