import React from 'react';
import fimg from '../images/logo-Solak-1-768x229.png';
import simg from '../images/location (1) copy.png';
import thimg from '../images/telephone (1).png';
import foimg from '../images/mail (1).png';
import fiimg from '../images/globe (1) copy.png';
import siimg from '../images/add-user.png';
import seimg from '../images/facebook.png';
import eigimg from '../images/instagram.png';
import ninimg from '../images/linkedin.png';
import vCardsJS from "vcards-js";
import '../asdrengrabovci.css';

function asdrengrabovci() {
    var vCard = vCardsJS();

    //set properties
    vCard.firstName = "Asdren";
    vCard.lastName = "Grebovci";
    vCard.organization = "Solak Energie";
    vCard.workPhone = "07 62 50 11 12";
    vCard.title = "Gerant";
    vCard.email="asg@solakenergie.fr";
    vCard.url = "www.solakenergie.fr";

  
  
    //get as formatted string
    console.log(vCard.getFormattedString());
    const downloadTxtFile = () => {
      const element = document.createElement("a");
      const file = new Blob([vCard.getFormattedString()], {
        type: "text/vcard"
      });
      element.href = URL.createObjectURL(file);
      element.download = "asdrengrabovci.vcf";
      document.body.appendChild(element);
      element.click();
    };
    return (
        <>
              
              <div className='arbes'>
            <div className="box1">
                <div className="logoo">
                    <img width="100px" height="100px" className="img1" src={fimg} alt="" />
                </div>
                <h2>Asdren Grabovci</h2>
                <p className="paragrafi">Gerant</p>
                <div className="footer">
                <div className="numri">
                        <img width="23px" height="23px" className="img3" src={thimg} alt="" />
                        <p className="p2"><a href='tel:0762501112'>07 62 50 11 12</a></p>
                    </div>
                    <div className="email">
                        <img width="23px" height="23px" src={foimg} alt="" className="img4" />
                        <p className="p2"><a href='mailto:asg@solakenergie.fr'>asg@solakenergie.fr</a></p>
                    </div>
                    <div className="glob">
                        <img width="23px" height="23px" src={fiimg} alt="" className="img5" />
                        <p className="p2"><a href='https://solakenergie.com/'>www.solakenergie.fr</a></p>
                    </div>
                    <div className="lokacioni">
                        <img width="23px" height="23px" src={simg} alt="" className="img2" />
                        <p className="p2"><a href='https://www.google.com/maps/place/SOLAK+ENERGIE/@45.779885,4.934449,14z/data=!4m5!3m4!1s0x0:0xf5259a8cdf3d7af!8m2!3d45.7798846!4d4.9344487?hl=fr-FR&shorturl=1'>4 rue Jean-Louis Claderon 69120 Vaulx-en-Velin</a></p>
                    </div>
                    
                </div>
                <div className="butoni">
                    <button className="butooni" onClick={downloadTxtFile} ><img width="30px" src={siimg} alt="" /></button>
                </div>
                <div className="rrjetet">
                    <img width="30px" src={seimg} alt="" className="insta" />
                    <img width="30px" src={eigimg} alt="" className="fb" />
                    <img width="30px" src={ninimg} alt="" className="lk" />
                </div>
                <div className="copyrigh">
                    <p className="i">Copyright by <a href="https://dolphinsgroup.eu/">Dolphins Group</a> </p>
                </div>
                </div>
            </div>

        </>
    )
}

export default asdrengrabovci