import React from 'react';
import vCardsJS from "vcards-js";
import a from '../images/kseco1.png'
import c from '../images/instagram (3).png'
import d from '../images/facebook (3).png'
import e from '../images/linkedin (1).png'


function Besim(){
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>
  
    var vCard = vCardsJS();
  
    //set properties
    vCard.firstName = "Besim";
    vCard.lastName = "Zukaj";
    vCard.organization = "KSECO";
    vCard.workPhone = "+41 43 499 09 09";
    vCard.cellPhone = "+41 78 806 97 77"
    vCard.email="b.zukaj@kseco.ch";
    vCard.url = "https://dolphinsgroup.eu/";
  
  
    //get as formatted string
    console.log(vCard.getFormattedString());
    const downloadTxtFile = () => {
      const element = document.createElement("a");
      const file = new Blob([vCard.getFormattedString()], {
        type: "text/vcard"
      });
      element.href = URL.createObjectURL(file);
      element.download = "besimzukaj.vcf";
      document.body.appendChild(element);
      element.click();
    };
      return (
        <>
<div>
<div class="tw-uses fit" id="more">
                <div class="tw-img">
                    <div class="card-w">
                   

                        <div class="header-a">
                            
                            <div class="altb">
                                <img src={a} alt=""/>
                                    <h1 class="title-w fita">Besim Zukaj</h1>
                                    <p className='topi'>dipl. betr. oec.</p>
                                    <p className='topi'>Mitglied der Geschäftsleitung</p>
                            </div>
                           
                        </div>

                        <div class="div-c">
                        <div class="info-w asb">
                                <i class="fas fa-phone-alt p-w"></i>
                                <a href="tel:+41434990909">+41 43 499 09 09</a>
                            </div>

                            <div class="info-w asb">
                                <i class="fas fa-phone-alt p-w"></i>
                                <a href="tel:+41788069777">+41 78 806 97 77</a>
                            </div>

                            <div class="info-w asb">

                                <i class="fas fa-envelope p-w"></i>
                                <a href="mailto:b.zukaj@kseco.ch">b.zukaj@kseco.ch</a>
                            </div>
                            <div class="info-w asb">
                            <i class="fa fa-location-arrow"></i>
                         <a href="https://www.google.com/maps/place/Z%C3%BCrcherstrasse+20,+8952+Schlieren,+Schweiz/@47.397954,8.451173,15z/data=!4m5!3m4!1s0x47900c75e039b9f1:0x5928715663f15483!8m2!3d47.3979539!4d8.4511726?hl=de">Zürcherstrasse 20,CH-8952 Schlieren </a>
                         </div>
                         <div class="info-w asb">

<i class="fas fa-globe p-w"></i>
<a href="https://dolphinsgroup.eu/">www.dolphinsgroup.eu</a>
</div>
                        </div>

                        <div className='rrjete'>
                            <a href="https://instagram.com/dolphinsgroupeu?igshid=MDM4ZDc5MmU=" className='what fg'>
                            <img src={c} className="ss" /></a>
                            <a href="https://www.facebook.com/dolphins.eugroup" className='what fg'>
                            <img src={d}  className="ss"/></a>
                            <a href="https://www.linkedin.com/company/dolphinsgroup?original_referer=" className='what'>
                            <img src={e} className="ss" /></a>
                        </div>
                  
                        <button class="save-w awb" onClick={downloadTxtFile}>
                           Save Contact
                            <i class="fas fa-download ml-w "></i>
                        </button>
                    </div>
                </div>
                </div>
                </div>


</>

);
}

export default Besim;
