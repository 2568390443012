import React from 'react';
import vCardsJS from "vcards-js";
import b from '../images/kuoni.png'
import c from '../images/instagram (3).png'
import d from '../images/facebook (3).png'
import e from '../images/youtube.png'
import email from'../images/mail.png'
import phone from '../images/call (1).png'
import globe from '../images/globe (1).png'
import location from '../images/location (1).png'
import user from '../images/add-user (2).png'

function Kuoni(){
  <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>

  var vCard = vCardsJS();

  //set properties
  vCard.firstName = "Candy ";
  vCard.lastName = "Zacheo Martinez";
  vCard.organization = "KUONI Reisen";
  vCard.workPhone = "+41 58 702 64 10";
  vCard.title = "Leiterin Filiale";
  vCard.email="candida.zacheo@kuoni-reisen.ch";
  vCard.url = "https://www.kuoni.ch/";



  //get as formatted string
  console.log(vCard.getFormattedString());
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([vCard.getFormattedString()], {
      type: "text/vcard"
    });
    element.href = URL.createObjectURL(file);
    element.download = "kuonireisen.vcf";
    document.body.appendChild(element);
    element.click();
  };
    return (
      <>
  

  <div>
<div class="tw-uses fit" id="more">
                <div class="tw-img">
                    <div class="card-w card-d">
                        <div class="header-a header-c">
                            <div class="alt kuon">
                             
                                    <h1 class="title-w besimz candy">Candy Zacheo Martinez</h1>
                                    <p className='delfi candy'>Leiterin Filiale</p>
                            </div>                         
                        </div>
                        <div className='dolpsoc perkuon'>
                            <a href="https://www.facebook.com/kuonireisen/" className='socico'>
                            <img src={d} className="ico" /></a>
                            <a href="https://www.instagram.com/kuoni_reisen/" className='socico'>
                            <img src={c}  className="ico"/></a>
                            <a href="https://www.youtube.com/@KuoniReisen" className='socico'>
                            <img src={e} className="ico" /></a>
                        </div>

                        <div class="div-bes">
                       
                        <div class="info-b  first">
                            <div class="inf">
                        <p>Mobile</p></div>
                           <div class="inf"><img src={phone} className='icons'/><a href="tel:+41587026410">+41 58 702 64 10</a></div>
                            </div>
                        </div>
                        <div class="div-bes">
                       
                        <div class="info-b">
                                <p>Email</p>
                                <div class="inf">
                               <img src={email} className='icons'/> <a href="mailto:candida.zacheo@kuoni-reisen.ch">candida.zacheo@kuoni-reisen.ch</a>
                            </div> </div>
                           
                        </div>
                        <div class="div-bes">
                       
                        <div class="info-b">
                                <p>Website</p>
                                <div class="inf">
                                <img src={globe} className='icons'/><a href="https://www.kuoni.ch/">www.kuoni.ch</a>
                            </div></div>
                            
                        </div>
                        <div class="div-bes fund">
                       
                       <div class="info-b ">
                               <p>Location</p>
                               <div class="inf">
                               <img src={location} className='icons'/> <a href="https://www.google.com/maps/place/KUONI+Reiseb%C3%BCro+Schlieren/@47.3982597,8.4448639,17z/data=!4m15!1m8!3m7!1s0x47900c74026fb82b:0x46d973cad490306f!2sSchlieren+Parkside,+Ringstrasse+7,+8952+Schlieren,+Switzerland!3b1!8m2!3d47.3982597!4d8.4470526!16s%2Fg%2F11rg64fw56!3m5!1s0x47900c7414f7fba9:0xcd87a1aa59d81e21!8m2!3d47.3982597!4d8.4470526!16s%2Fg%2F11b6dg9z00">Ringstrasse 7 - CH-8952
Schlieren Switzerland</a>
                           </div>

                           
                           
                           </div>
      
                           <div class="inf useri">
                           <button onClick={downloadTxtFile}><img src={user} className='icons'/></button> 
                           </div>
                        
                       </div>
                       <div class="div fund copy">
                       
                       <div className="copyrighti">
                               <p className='copyright'>© Copyright 2023. <a href="https://dolphinstech.eu/nfcdeutsch.html">Dolphins Group</a></p>
                              

                           
                           
                           </div>
      
                           
                        
                       </div>
                  
                        
                    </div>
                </div>
                </div>
                </div>


</>

  );
}

export default Kuoni;