import React from 'react';
import './styles.css';
import Home from './components/home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import Adnan from './components/adnanneziraj';
import Naim from './components/naimzukaj';
import Fitim from './components/fitimradoniqi';
import John from './components/johnlluka';
import Kenny from './components/kennylluka';
import Armend from './components/armendalidemaj';
import Besim from './components/besimzukaj';
import Astriti from './components/mobileshopastriti';
import Example from './components/example';
import DolphinsBesim from './components/besim-zukaj';
import Denis from './components/denisgafuri';
import Elvion from './components/elviongashi';
import Arbnor from './components/arbnorzogaj';
import Havaja from './components/havaja';
import Xheneta from './components/xhenetahoti';
import Viona from './components/butikuviona';
import Teuta from './components/teutaaliu';
import Arjanit from './components/arjanitzukaj';
import Ylli from './components/ylliqorraj';
import Emin from './components/eminbogiqi';
import Kushtrim from './components/kushtrimlulaj';
import Arianit from './components/arianitjasiqi';
import Kuoni from './components/kuoni';
import Nila from './components/nilaamini';
import asdrengrabovci from './components/asdrengrabovci';
import Arben from './components/arbenzyba';
import Orgen from './components/orgenhaxhijaj';
import Jakob from './components/jakobeipp';
import Jan from './components/janschwager';
import Stefan from './components/stefanrohrl';
import Philipp from './components/philippferrer';
import Justin from './components/justincassels';
import Robert from './components/robertroever';
import Nicholas from './components/nicholassprung';
import Johni from './components/johnshnaidman';
import Roger from './components/rogerbauer';
import Jashar from './components/jasharbielnberg';



function App() {
  return (
    <>
    
      <HashRouter>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/arbenzyba' component={Arben} />
          <Route path='/asdrengrabovci' component={asdrengrabovci} />
          <Route path='/adnanneziraj' component={Adnan} />
          <Route path='/naimzukaj' component={Naim} />
          <Route path='/fitimradoniqi' component={Fitim} />
          <Route path='/johnlluka' component={John} />
          <Route path='/kennylluka' component={Kenny} />
          <Route path='/armendalidemaj' component={Armend} />
          <Route path='/besimzukaj' component={Besim} />
          <Route path='/mobileshopastriti' component={Astriti} />
          <Route path='/example' component={Example} />
          <Route path='/besim-zukaj' component={DolphinsBesim} />
          <Route path='/denisgafuri' component={Denis} />
          <Route path='/elviongashi' component={Elvion} />
          <Route path='/arbnorzogaj' component={Arbnor} />
          <Route path='/havaja' component={Havaja} />
          <Route path='/xhenetashoti' component={Xheneta} />
          <Route path='/butikuviona' component={Viona} />
          <Route path='/teutaaliu' component={Teuta} />
          <Route path='/arjanitzukaj' component={Arjanit} />
          <Route path='/ylliqorraj' component={Ylli} />
          <Route path='/eminbogiqi' component={Emin} />
          <Route path='/kushtrimlulaj' component={Kushtrim} />  
          <Route path='/arianitjasiqi' component={Arianit} />     
          <Route path='/kuoni' component={Kuoni} />    
          <Route path='/nilaamini' component={Nila} />   
          <Route path='/orgenhaxhijaj' component={Orgen}     />       
          <Route path='/jakobeipp' component={Jakob}     /> 
          <Route path='/janschwager' component={Jan} />
          <Route path='/stefanrohrl' component={Stefan} />
          <Route path='/philippferrer' component={Philipp} />
          <Route path='/johnshnaidman' component={Johni} />
          <Route path='/justincassels' component={Justin} />
          <Route path='/robertroever' component={Robert} />
          <Route path='/nicholassprung' component={Nicholas} />
          <Route path='/rogerbauer' component={Roger} />   
          <Route path='/jasharbielnberg' component={Jashar} />       
        </Switch>
      </HashRouter>
    </>
  );
}

export default App;
