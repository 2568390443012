import React from 'react';
import vCardsJS from "vcards-js";
import b from '../images/mobileshopastr.png'


function Astriti(){
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>
  
    var vCard = vCardsJS();
  
    //set properties
    vCard.firstName = "Astriti Mobile shop";

    vCard.workPhone = "+386 49 679 444";

    vCard.email="mobilshopastriti@hotmail.com";
 
  
  
    //get as formatted string
    console.log(vCard.getFormattedString());
    const downloadTxtFile = () => {
      const element = document.createElement("a");
      const file = new Blob([vCard.getFormattedString()], {
        type: "text/vcard"
      });
      element.href = URL.createObjectURL(file);
      element.download = "mobileshopastriti.vcf";
      document.body.appendChild(element);
      element.click();
    };
      return (
        <>
<div className='astritii'>
<div class="tw-uses fit " id="more">
                <div class="tw-img">
                    <div class="card-w nan card-sa">
                        <div class="head">
                            <div class="alta">
                                <img src={b} alt=""/>
                                   
                                    <p className='mobile'>Mobile Shop</p>
                            </div>
                           
                        </div>

                        <div class="div-c div-v">
                        <div class="info-w as how">
                                <i class="fas fa-phone-alt p-w"></i>
                                <a href="tel:+38649679444">+386 49 679 444</a>
                            </div>

                     

                       

                            <div class="info-w as how">

                                <i class="fas fa-envelope p-w"></i>
                                <a href="mailto:mobilshopastriti@hotmail.com">mobilshopastriti@hotmail.com</a>
                            </div>
                            <div class="info-w as how">
                            <i class="fa fa-location-arrow"></i>
                         <a href="https://www.google.com/maps/dir/42.6663801,21.1587273/Mobil+Shop+ASTRITI,+Elliot+Engel,+Peja/@42.6730736,20.4461406,10z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x1352fdc83ef7a9a3:0x61e5a7f23018997e!2m2!1d20.2928873!2d42.6586704">Rr. Eliot Engel, Pejë,</a>
                         </div>
                         
                         <div class="info-w as how socia">

                         <i class="fa fa-facebook p-w"></i>
<a href="https://www.facebook.com/astritishop1">Mobil Shop Astriti</a>
</div>

<div class="info-w as how socia">

<i class="fa fa-instagram p-w"></i>
<a href="mhttps://instagram.com/mobilshopastriti?igshid=ZmRlMzRkMDU=">mobilshopastriti</a>
</div>
                           
                        </div>
                  
                        <button class="save-w aw mob" onClick={downloadTxtFile}>
                         SAVE CONTACT
                        </button>
                    </div>
                </div>
                </div>
                </div>


</>

);
}

export default Astriti;
