import React from 'react';
import vCardsJS from "vcards-js";
import b from '../images/havaja.jpg'
import  d from '../images/instagram (4).png'
import e from '../images/viber (1).png'
import f from '../images/whatsapp.png'
import g from '../images/map.png'
import dress1 from '../images/dress1.png'
import dress2 from '../images/dress2.png'
import dress3 from '../images/dress3.png'
import dress4 from '../images/dress4.png'




function Havaja(){
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>
  
    var vCard = vCardsJS();
  
    //set properties
    vCard.firstName = "Adnan ";
    vCard.lastName = "Zena";
    vCard.organization = "Shtepia e modes Havaja";
    vCard.workPhone = "+383 44 831 832";
    
  
  
    //get as formatted string
    console.log(vCard.getFormattedString());
    const downloadTxtFile = () => {
      const element = document.createElement("a");
      const file = new Blob([vCard.getFormattedString()], {
        type: "text/vcard"
      });
      element.href = URL.createObjectURL(file);
      element.download = "havaja.vcf";
      document.body.appendChild(element);
      element.click();
    };
      return (
        <>
<div>
<div class="card" id="more">
<div class="bg-two hava">
    <div className='socialin'>
    <img src={dress1} className="sochava dress1"/>
    <img src={dress2} className="sochava dress2"/>
    </div>
            <br/>
          
            <img src={b} class="dive juaji"/>

            <div className='socialin'>
            <img src={dress3} className="sochava dress3"/>
            <img src={dress4} className="sochava dress4"/>
            </div>
        </div>
        <div class="text perH">
           <h1 class="title namest">Advan Zena</h1>
                <h1 class="ceo">Pronarë</h1>
        </div>
        <br/>
        <div class="personal">
     <div class="info des ex havaja">
     <img src={e} className="sochava"/>
        <a href="viber://chat/?number=%2B38344831832">+383 44 831 832</a>
     </div>
     <div class="info des ex havaja">
        
     <img src={f} className="sochava"/>
        <a href="https://wa.me/38344831832">+383 44 831 832</a>
     </div>
     <div class="info des des ex havaja">
        
     <img src={d} className="sochava"/>
        <a href="https://instagram.com/havaja1994?igshid=MDM4ZDc5MmU="> havaja1994</a>
     </div>
     <div class="info des  ex havaja fff">
        
        <img src={g} className="sochava"/>
        <a href="https://www.google.com/maps/place/Ilir+Konushevci,+Prishtina/@42.6695193,21.1611125,17z/data=!3m1!4b1!4m5!3m4!1s0x13549f1f2f85a2e9:0x4d136a179da7a0fb!8m2!3d42.6695154!4d21.1633012">Rr.Ilir Konushevci, Prishtinë
</a>
     </div>
        </div>
        <div class="txt-center">
            
        <button class="save-btn alli" onClick={downloadTxtFile}>SAVE CONTACT <i class="fas fa-download ml"></i></button>
        </div>
                </div>
                </div>


</>

);
}

export default Havaja;
