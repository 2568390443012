import React from 'react';
import vCardsJS from "vcards-js";
import b from '../images/logo juaj (2).png'
import  c from '../images/facebook (1).png'
import  d from '../images/instagram (1).png'
import  e from '../images/twitter (1).png'


function Example(){
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>
  
    var vCard = vCardsJS();
  
    //set properties
    vCard.firstName = "Name ";
    vCard.lastName = "Surname";
    vCard.organization = "Example";
    vCard.workPhone = "+383 48 xxx xxx";
    vCard.email="example@gmail.com";
    vCard.url = "www.example.com";
  
  
    //get as formatted string
    console.log(vCard.getFormattedString());
    const downloadTxtFile = () => {
      const element = document.createElement("a");
      const file = new Blob([vCard.getFormattedString()], {
        type: "text/vcard"
      });
      element.href = URL.createObjectURL(file);
      element.download = "example.vcf";
      document.body.appendChild(element);
      element.click();
    };
      return (
        <>
<div>
<div class="card" id="more">
<div class="bg-two">
    <div className='socialin'>
        <img src={c} />
        <img src={d} />
        <img src={e} />
    </div>
            <br/>
          
            <img src={b} class="dive juaj"/>
        </div>
  
        <div class="text">
        
        <p className='slogi'></p>
            <h1 class="title namest">Name Surname</h1>
                <h1 class="ceo">CEO/Co-founder</h1>
          
        </div>
        <br/>
        <div class="personal">
     <div class="info des ex">
        <i class="fas fa-phone-alt p"></i> 
        <a href="tel:+383 48 xxx xxx">+383 48 XXX XXX</a>
     </div>
     <div class="info des ex">
        
        <i class="fas fa-envelope p"></i> 
        <a href="mailto:example@gmail.com">example@gmail.com</a>
     </div>
     <div class="info des des ex">
        
        <i class="fas fa-globe p"></i>
        <a href="#"> www.example.com</a>
     </div>
     <div class="info des desi ex">
        
     <i class="fas fa-location-arrow p"></i>
        <a href="">1201 Pleasantville Rd.
<br/><p class="loc">New York</p></a>
     </div>
        </div>
        <div class="txt-center">
            
        <button class="save-btn alli" onClick={downloadTxtFile}>SAVE CONTACT <i class="fas fa-download ml"></i></button>
        </div>
                </div>
                </div>


</>

);
}

export default Example;
