import React from 'react';
import vCardsJS from "vcards-js";
import b from '../images/denis.png'
import  c from '../images/facebook (1).png'
import  d from '../images/instagram (1).png'
import  e from '../images/tiktok.png'
import  f from '../images/youtube.png'
import  g from '../images/pss.png'
import a from '../images/dgcons.jpg'


function Denis(){
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>
  
    var vCard = vCardsJS();
  
    //set properties
    vCard.firstName = "Denis ";
    vCard.lastName = "Gafuri";
    vCard.organization = "Sales Training";
    vCard.workPhone = "+383 49 668 900";
    vCard.email="sales@denisgafuri.com";
    vCard.url = "www.denisgafuri.com";
  
  
    //get as formatted string
    console.log(vCard.getFormattedString());
    const downloadTxtFile = () => {
      const element = document.createElement("a");
      const file = new Blob([vCard.getFormattedString()], {
        type: "text/vcard"
      });
      element.href = URL.createObjectURL(file);
      element.download = "denisgafuri.vcf";
      document.body.appendChild(element);
      element.click();
    };
      return (
        <>
<div>
<div class="card denisit" id="more">
<div class="bg ">
 
    
            <img src={b} class="denislogo"/>
            <div className='logo2'><img src={a} className='dgco' /></div>
        </div>
  
        <div class="text dnie">
        
       
            <h1 class="title namest deno">Denis Gafuri</h1>
                <h1 class="ceo deno">CEO</h1>
          
        </div>
        <br/>
        <div class="personal denisit">
     <div class="info des ex">
        <i class="fas fa-phone-alt p"></i> 
        <a href="tel:+38349668900">+383 49 668 900</a>
     </div>
     
     <div class="info des ex">
        
        <i class="fas fa-envelope p"></i> 
        <a href="mailto:sales@denisgafuri.com">sales@denisgafuri.com</a>
     </div>
     <div class="info des des ex">
        
        <i class="fas fa-globe p"></i>
        <a href="https://denisgafuri.com/">www.denisgafuri.com</a>
     </div>
     <div class="info des des ex">
        
        <i class="fas fa-location-arrow p"></i>
        <a href="https://www.google.com/maps/place/Denis+Gafuri+Sales+Training/@42.646614,21.1474868,17z/data=!3m1!4b1!4m6!3m5!1s0x13549f7694da61fd:0x75ed41e890e10679!8m2!3d42.6466101!4d21.1496755!16s%2Fg%2F11tb5rwhmr">Rr.Rexhep Krasniqi, Prishtina 10000</a>
     </div>
   
        </div>
        <div className='socialin'>
        <a href='https://www.facebook.com/denisgafurisalestraining/'><img src={c} /></a>
        <a href='https://www.instagram.com/denisgafuri/?hl=en'><img src={d} /></a>
        <a href='https://www.tiktok.com/@denisgafuri'><img src={e} /></a>
        <a href='https://www.youtube.com/channel/UCGavaRTCb4JpszhWrRexlUQ'><img src={f} /></a>
        <a href='https://pss.denisgafuri.com/rando1670254542042'><img src={g} /></a>
    </div>
        <div class="txt-center">
            
        <button class="save-btn alli denisit" onClick={downloadTxtFile}>SAVE CONTACT <i class="fas fa-download ml"></i></button>
        </div>
                </div>
                </div>


</>

);
}

export default Denis;
