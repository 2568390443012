import React from 'react';
import vCardsJS from "vcards-js";
import b from '../images/shortlogo.jpg'
import c from '../images/instagram (3).png'
import d from '../images/facebook (3).png'
import e from '../images/linkedin (1).png'
import email from'../images/mail.png'
import phone from '../images/call (1).png'
import globe from '../images/globe (1).png'
import location from '../images/location (1).png'
import user from '../images/add-user (2).png'


function Teuta(){
  <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>

  var vCard = vCardsJS();

  //set properties
  vCard.firstName = "Teuta";
  vCard.lastName = "Aliu";
  vCard.workPhone = "+383 49 227 899";
  vCard.otherPhone = "+383 48 629 999";
  vCard.url = "https://dolphinsgroup.eu/";
  vCard.workEmail = "info@dolphinsgroup.eu";


  //get as formatted string
  console.log(vCard.getFormattedString());
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([vCard.getFormattedString()], {
      type: "text/vcard"
    });
    element.href = URL.createObjectURL(file);
    element.download = "teutaaliu.vcf";
    document.body.appendChild(element);
    element.click();
  };
    return (
      <>
  

  <div>
<div class="tw-uses fit" id="more">
                <div class="tw-img">
                    <div class="card-w card-d">
                        <div class="header-a header-b">
                            <div class="alt">
                                <div className='dolphlogo'>
                                <img src={b} alt=""/>
                                </div>
                                    <h1 class="title-w besimz">Teuta Aliu</h1>
                                    
                            </div>
                           
                        </div>
                        <div className='dolpsoc'>
                            <a href="https://instagram.com/dolphinsgroupeu?igshid=MDM4ZDc5MmU=" className='socico'>
                            <img src={c} className="ico" /></a>
                            <a href="https://www.facebook.com/dolphins.eugroup" className='socico'>
                            <img src={d}  className="ico"/></a>
                            <a href="https://www.linkedin.com/company/dolphinsgroup?original_referer=" className='socico'>
                            <img src={e} className="ico" /></a>
                        </div>

                        <div class="div-bes">
                       
                        <div class="info-b  first">
                            <div class="inf">
                           <p>Mobile</p></div>
                           <div class="inf"><img src={phone} className='icons'/><a href="tel:+38349227899">+383 49 227 899</a></div>
                           <div class="inf"><img src={phone} className='icons'/><a href="tel:+38348629999">+383 48 629 999</a></div>
                            </div>
                       
                            
                        </div>
                        <div class="div-bes">
                       
                        <div class="info-b">
                                <p>Email</p>
                                 </div>
                            <div class="info-b">
                            <div class="inf">
                            <img src={email} className='icons'/> <a href="mailto:info@dolphinsgroup.eu">info@dolphinsgroup.eu</a>
                            </div></div>
                        </div>
                        <div class="div-bes">
                       
                        <div class="info-b">
                                <p>Website</p>
                                <div class="inf">
                                <img src={globe} className='icons'/><a href="https://dolphinsgroup.eu/">www.dolphinsgroup.eu</a>
                            </div></div>
                            <div class="info-b">
                            <div class="inf">
                            <img src={globe} className='icons'/><a href="https://dolphinstech.eu/">www.dolphinstech.eu</a>
                            </div></div>
                        </div>
                        <div class="div-bes fund">
                       
                       <div class="info-b ">
                               <p>Location</p>
                               <div class="inf">
                               <img src={location} className='icons'/> <a href="https://www.google.com/maps/place/Dolphins+Group/@42.6571135,20.2871878,17z/data=!3m1!4b1!4m5!3m4!1s0x1352fd327a5c6a01:0x33f8610caa3d9d3a!8m2!3d42.6571096!4d20.2893765">Kulla e Sheremetit , 30000, Pejë </a>
                           </div>

                           
                           
                           </div>
                           <div class="inf useri">
                           <button onClick={downloadTxtFile}><img src={user} className='icons'/></button> 
                           </div>
                        
                       </div>
                  
                        
                    </div>
                </div>
                </div>
                </div>


</>

  );
}

export default Teuta;