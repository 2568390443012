import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../nfc.css';
import '../test.css';
import 'font-awesome/css/font-awesome.min.css';
import logo from '../images/3.png';
import vCardsJS from "vcards-js";




function Home() {
  var vCard = vCardsJS();

  //set properties
  vCard.firstName = "Name";
  vCard.lastName = "Surname";
  vCard.workPhone = "+383-452-452";
  vCard.cellPhone = "+383-452-452";
  vCard.email="email@gmail.com";
  vCard.url = "www.example.com";


  //get as formatted string
  console.log(vCard.getFormattedString());
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([vCard.getFormattedString()], {
      type: "text/vcard"
    });
    element.href = URL.createObjectURL(file);
    element.download = "example.vcf";
    document.body.appendChild(element);
    element.click();
  };
    const [active, setActive] = useState("nav__menu");
    const [icon, setIcon] = useState("nav__toggler");
    const navToggle = () => {
        if (active === "nav__menu") {
            setActive("nav__menu nav__active");
        } else setActive("nav__menu");

        // Icon Toggler
        if (icon === "nav__toggler") {
            setIcon("nav__toggler toggle");
        } else setIcon("nav__toggler");
    };
    return (
        <>
          

<div className='dollogo'>
    <img src={logo} />
</div>
        </>

    );
}

export default Home;