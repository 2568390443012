import React from 'react';
import vCardsJS from "vcards-js";
import c from '../images/instagram (4).png'
import d from '../images/facebook (4).png'
import phone from '../images/telephone-call.png'
import user from '../images/add-user (3).png'
import  e from '../images/gmail.png'
import s from '../images/snapchat (1).png'


function Arbnor(){
  <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>

  var vCard = vCardsJS();

  //set properties
  vCard.firstName = "Arbnor";
  vCard.lastName = "Zogaj";
  vCard.email = "arbnorzogaj@hotmail.com";
  vCard.otherEmail = "albzogaj1@gmail.com";
  vCard.cellPhone = " +383 49 715 138";
  vCard.socialUrls = "https://instagram.com/arbnorzogaj?igshid=YmMyMTA2M2Y=";
  vCard.socialUrls = "https://www.facebook.com/alBZogaj";
  vCard.socialUrls = "https://www.snapchat.com/add/azalb";


  //get as formatted string
  console.log(vCard.getFormattedString());
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([vCard.getFormattedString()], {
      type: "text/vcard"
    });
    element.href = URL.createObjectURL(file);
    element.download = "arbnorzogaj.vcf";
    document.body.appendChild(element);
    element.click();
  };
    return (
      <>
  

  <div>
<div class="tw-uses fit" id="more">
                <div class="tw-img">
                    <div class="card-w card-d">
                        <div class="header-a header-b header-person">
                            <div class="alt">
                                
                                    <h1 class="title-w title-person">ARBNOR ZOGAJ</h1>
                                    
                            </div>
                           
                        </div>
                      

                        <div class="div-bes">
                       
                        <div class="info-b  first">
                            
                        <a href="tel:+38349715138"> <div class="inf person"><img src={phone} className='iconperson'/><a href="tel:+38349715138">+383 49 715 138</a></div></a>
                            </div>
                        
                            
                        </div>
                        <div class="div-bes">
                       
                        <div class="info-b">
                        <a href="">
                                <div class="inf person">
                               <img src={e} className='iconperson'/><div className='emajl'> <a href="mailto:arbnorzogaj@hotmail.com"> arbnorzogaj@hotmail.com</a>
                               <a href="mailto:albzogaj1@gmail.com"> albzogaj1@gmail.com</a></div>
                            </div></a> </div>
                           
                        </div>
                        <div class="div-bes">
                       
                        <div class="info-b">
                        <a href="https://instagram.com/arbnorzogaj?igshid=YmMyMTA2M2Y=">
                                <div class="inf person">
                               <img src={c} className='iconperson'/> <a href="https://instagram.com/arbnorzogaj?igshid=YmMyMTA2M2Y="> arbnorzogaj</a>
                            </div></a> </div>
                           
                        </div>
                        <div class="div-bes">
                       
                        <div class="info-b">
                        <a href="https://www.facebook.com/alBZogaj">
                                <div class="inf person">
                                <img src={d} className='iconperson'/><a href="https://www.facebook.com/alBZogaj"> Arbnor Zogaj</a>
                            </div></a>
                            </div>
                            
                        </div>
                        <div class="div-bes fund funus">
                       
                       <div class="info-b ">
                       <a href="https://www.snapchat.com/add/azalb">
                               <div class="inf person">
                               <img src={s} className='iconperson'/> <a href="https://www.snapchat.com/add/azalb"> azalb</a>
                           </div></a>

                           
                           
                           </div>
                           <div class="inf useri useripers">
                           <button onClick={downloadTxtFile}><img src={user} className='iconperson'/></button> 
                           </div>
                        
                       </div>
                  
                        
                    </div>
                </div>
                </div>
                </div>


</>

  );
}

export default Arbnor;