import facbookimg from '../images/facebook.png';
import instagramimg from '../images/instagram.png';
import vCardsJS from "vcards-js";
import logoimg from '../images/logo.png';
import '../arbenzyba.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone , faEnvelope , faLocationDot , faGlobe , faUserPlus } from '@fortawesome/free-solid-svg-icons';

function Arben() {
  var vCard = vCardsJS();

  //set properties
  vCard.firstName = "Arben";
  vCard.lastName = "Zyba";
  vCard.organization = "Illyrian Eagle";
  vCard.workPhone = "+41792713030";
  vCard.title = "CEO";
  vCard.email="info@illyrianeagle.ch";
  vCard.url = "https://illyrianeagle.ch/";



  //get as formatted string
  console.log(vCard.getFormattedString());
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([vCard.getFormattedString()], {
      type: "text/vcard"
    });
    element.href = URL.createObjectURL(file);
    element.download = "arbenzyba.vcf";
    document.body.appendChild(element);
    element.click();
  };

  return (
    <div className='arbeni'>
    <div className="data8" id="box-seven">
  <div className="dataa">
    <div class="first">
      <h1><img src={logoimg} style={{width: "250px"}} /></h1>
    </div>
    <div className="title">
      <h1 className='tname'>ARBEN ZYBA</h1>
      <h6 style={{textAlign:"center"}}>
        <p className='p-ps'>CEO/Co-Founder</p>
      </h6>
    </div>
    <div className="main-content">
      <div className="boxx">
      <FontAwesomeIcon icon={faPhone} className='faaa' />
        <p><a href='tel:+41792713030'>+41792713030</a></p>
      </div>
      <div className="boxx">
      <FontAwesomeIcon icon={faEnvelope} className='faaa' />
        <p><a href='mailto:info@illyrianeagle.ch'>info@illyrianeagle.ch</a></p>
      </div>
      <div className="boxx">
      <FontAwesomeIcon icon={faGlobe} className='faaa' />
        <p><a href='https://www.illyrianeagle.ch/'>www.illyrianeagle.ch</a></p>
      </div>
      <div className="boxx">
      <FontAwesomeIcon icon={faLocationDot}  className='faaa'/>
        <p><a href='https://www.google.com/maps/place/Zurich+Airport/@47.461701,8.55086,11z/data=!4m6!3m5!1s0x479075424052757d:0xc66b77d646887658!8m2!3d47.4617014!4d8.5508598!16zL20vMDFsazVr?hl=en&entry=ttu'>8058 Zürich Flughafen</a></p>
      </div>
      <button type="button" id="btn-five" className="btn-default" onClick={downloadTxtFile}>
        <FontAwesomeIcon icon={faUserPlus} style={{marginright: "0"}} className='faaa' />
      </button>
      <div className="social-media">
        <a href='https://www.facebook.com/IllyrianEagleOfficial/'><img src={facbookimg} width="40px"/></a>
        <a href='https://www.instagram.com/illyrianeagleofficial/'><img src={instagramimg} width="40px"/></a>
        
      </div>
    </div>

    <div>
      <h5 className='h5'>ⓒ Copyright by <a href="https://dolphinsgroup.eu/"></a>Dolphins Group</h5>
    </div>
    </div>
  </div>
</div>
  );
}

export default Arben;
