
import vCardsJS from "vcards-js";
import jashar from '../images/JASHAR.png';
import user from '../images/add-user (2).png'
import c from '../images/instagram (5).png'
import d from '../images/facebook (5).png'
import e from '../images/linkedin (1).png'





function Jashar() {
  var vCard = vCardsJS();

  //set properties
  vCard.firstName = "Jashar";
  vCard.lastName = "Bielenberg";
  vCard.organization = "GaLaBau-u abbruch & Tiefbau ";
  vCard.workPhone = "+491620804876";
  vCard.title = "Hauptgeschaftsfuhrer";
  vCard.email="jashar.bielenberg@yahoo.de";




  //get as formatted string
  console.log(vCard.getFormattedString());
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([vCard.getFormattedString()], {
      type: "text/vcard"
    });
    element.href = URL.createObjectURL(file);
    element.download = "jashar.vcf";
    document.body.appendChild(element);
    element.click();
  };

  return (
    <div className=''>
<div class="card" id="more">
<div class="bg-two b-liri">

            <br/>
          
            <img src={jashar} class="dive"/>
        </div>
        

        <div class="text perArch">
        
        <p className='slogi'></p>
            <h1 class="titles jashar">JASHAR BIELENBERG</h1>
                <p class="ceo ce-jashar">HAUPTGESCHÄFTSFÜHRER</p>
          
        </div>
     

     <div></div>
        <div class="div-bes div-liri div-jash">
                       
                       <div class="info-b info-l">
                      
                          <div class="inf inf-l"><i class="fa fa-phone fa-2x jashi"></i><a href="tel:+4917620804876">+4917620804876</a></div>
                           </div>
                       
                           
                       </div>
                       <div class="div-bes div-liri div-jash">
                      
                       <div class="info-b info-l">
                        
                               <div class="inf inf-l">
                               <i class="fa fa-envelope fa-2x jashi"></i> <a href="mailto:jashar.bielenberg@yahoo.de">jashar.bielenberg@yahoo.de</a>
                           </div> </div>
                          
                       </div>
                   
                       <div class="div-bes fundi div-liri div-jash di">
                      
                       <div class="info-b info-l ">
                             
                               <div class="inf inf-l">
                               <i class="fa fa-location-dot fa-2x jashi"></i><a href="#">79585 Steinen,SchulstraBe 15</a>
                           </div></div>
                          <div class="inf inf-l useri useri-halo">
                          <button onClick={downloadTxtFile}><img src={user} className='icons'/></button> 
                          </div>
                       
                      </div>

                      <div class="liri-soca">
                      
                     
                             
                              
                              <div className='dolpsoci'>
                            <a href="" className='socico'>
                            <img src={c} className="icolit" /></a>
                            <a href="" className='socico'>
                            <img src={d}  className="icolit"/></a>
                            <a href="" className='socico'>
                            <img src={e} className="icolit" /></a>
                          
                   
                          </div> 
                         
                      </div>
                      <div>
      <h5 className='h5'>ⓒ Copyright by <a href="https://dolphinsgroup.eu/"></a>Dolphins Group</h5>
    </div>
                 
                       
                   </div>
</div>
  );
}

export default Jashar;
