import React from 'react';
import vCardsJS from "vcards-js";
import b from '../images/dive.png'
import  c from '../images/facebook (1).png'
import  d from '../images/instagram (1).png'


function John(){
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>
  
    var vCard = vCardsJS();
  
    //set properties
    vCard.firstName = "John";
    vCard.lastName = "Lluka";
    vCard.organization = "Dive Oyster House";
    vCard.workPhone = "+1 914-7559606";
    vCard.email="xhavitlluka@gmail.com";
    vCard.url = "https://www.diveoysterhouse.com/";
  
  
    //get as formatted string
    console.log(vCard.getFormattedString());
    const downloadTxtFile = () => {
      const element = document.createElement("a");
      const file = new Blob([vCard.getFormattedString()], {
        type: "text/vcard"
      });
      element.href = URL.createObjectURL(file);
      element.download = "johnlluka.vcf";
      document.body.appendChild(element);
      element.click();
    };
      return (
        <>
<div>
<div class="card" id="more">
<div class="bg-two">

            <br/>
          
            <img src={b} class="dive"/>
        </div>
  
        <div className='flexi'>
        <div className='socialet'>
<a href='https://www.facebook.com/DIVE-Oyster-House-110057011332468'><img src={c} /></a>
        <a href='https://www.instagram.com/diveoysterhouse/'><img src={d} /></a>

                  </div>
        <div class="text">
        
        <p className='slogi'></p>
            <h1 class="title">John Lluka</h1>
                <h1 class="ceo">CEO / Co-Founder</h1>
          
        </div>
        </div>
        <div class="personal">
     <div class="info des">
        <i class="fas fa-phone-alt p"></i> 
        <a href="tel:+19147559606">+1 914-755-9606</a>
     </div>
     <div class="info des">
        
        <i class="fas fa-envelope p"></i> 
        <a href="mailto:xhavitlluka@gmail.com">xhavitlluka@gmail.com</a>
     </div>
     <div class="info des des">
        
        <i class="fas fa-globe p"></i>
        <a href="https://www.diveoysterhouse.com/"> www.diveoysterhouse.com</a>
     </div>
     <div class="info des desi">
        
     <i class="fas fa-location-arrow p"></i>
        <a href="https://www.google.com/maps?ll=41.150529,-73.830362&z=16&t=m&hl=en&gl=US&mapclient=embed&q=1201+Pleasantville+Rd+Briarcliff+Manor,+NY+10510+USA">1201 Pleasantville Rd.
<br/><p class="loc">Briarcliff Manor, New York</p></a>
     </div>
        </div>
        <div class="txt-center">
            
        <button class="save-btn" onClick={downloadTxtFile}>SAVE CONTACT <i class="fas fa-download ml"></i></button>
        </div>
                </div>
                </div>


</>

);
}

export default John;
