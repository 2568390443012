import React from 'react';
import vCardsJS from "vcards-js";
import a from '../images/logo z.png'
import b from '../images/litchi.jpg'


function Armend(){
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>
  
    var vCard = vCardsJS();
  
    //set properties
    vCard.firstName = "Armend";
    vCard.lastName = "Alidemaj";
    vCard.organization = "Zahn Design";
    vCard.workPhone = "+41 79 888 58 58";
    vCard.email="info@zahndesign.ch";
    vCard.url = "https://www.zahndesign.ch";
  
  
    //get as formatted string
    console.log(vCard.getFormattedString());
    const downloadTxtFile = () => {
      const element = document.createElement("a");
      const file = new Blob([vCard.getFormattedString()], {
        type: "text/vcard"
      });
      element.href = URL.createObjectURL(file);
      element.download = "armendalidemaj.vcf";
      document.body.appendChild(element);
      element.click();
    };
      return (
        <>
<div>
<div class="tw-uses fit" id="more">
                <div class="tw-img">
                    <div class="card-w alija">
                        <div class="header-a">
                            <div class="alti">
                                <div className='llogot'>
                                <img src={a} alt="" className='zahnlogo'/>
                                <img src={b} alt=""/>
                                </div>
                                    <h1 class="title-w fita">Armend Alidemaj</h1>
                                   
                            </div>
                           
                        </div>

                        <div class="div-b">
                        <div class="info-w asi">
                                <i class="fas fa-phone-alt p-w"></i>
                                <a href="tel:+41798885858">+41 79 888 58 58</a>
                            </div>

                            <div class="info-w asi">
                            <i class="fas fa-envelope p-w"></i>
                                <a href="mailto:info@zahndesign.ch">info@zahndesign.ch</a>
                            </div>

                            <div class="info-w asi">

                                <i class="fas fa-globe p-w"></i>
                                <a href="https://www.zahndesign.ch">www.zahndesign.ch</a>
                            </div>
                            <div class="info-w asi">
                            <i class="fa fa-location-arrow"></i>
                         <a href="https://www.google.com/maps/place/Hertistrasse+27,+8304+Wallisellen,+Zvic%C3%ABr/@47.415712,8.5741482,17z/data=!3m1!4b1!4m5!3m4!1s0x479aa068fe4a5ffb:0xe43bb94bd2e5662a!8m2!3d47.415712!4d8.5763369">Hertistrasse 27, 8304 Wallisellen</a>
                         </div>
                            
                        </div>
                  
                        <button class="save-w awi" onClick={downloadTxtFile}>
                           Save Contact
                            <i class="fas fa-download ml-w "></i>
                        </button>
                    </div>
                </div>
                </div>
                </div>


</>

);
}

export default Armend;
