import React from 'react';
import vCardsJS from "vcards-js";
import b from '../images/top_reklama_logo.png'
import viber from '../images/viber.png'
import qr from '../images/qr-code.png';

function Fitim(){
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>
  
    var vCard = vCardsJS();
  
    //set properties
    vCard.firstName = "Fitim";
    vCard.lastName = "Radoniqi";
    vCard.organization = "Top Reklama";
    vCard.workPhone = "+383 49 509 928";
    vCard.cellPhone = "+383 48 519 415";
    vCard.homePhone = "+383 38 775 775"
    vCard.email="fitim@top-reklama.com";
    vCard.url = "https://top-reklama.com/";
  
  
    //get as formatted string
    console.log(vCard.getFormattedString());
    const downloadTxtFile = () => {
      const element = document.createElement("a");
      const file = new Blob([vCard.getFormattedString()], {
        type: "text/vcard"
      });
      element.href = URL.createObjectURL(file);
      element.download = "fitimradoniqi.vcf";
      document.body.appendChild(element);
      element.click();
    };
      return (
        <>
<div>
<div class="tw-uses fit" id="more">
                <div class="tw-img">
                    <div class="card-w">
                        <div class="header-a">
                            <div class="alt">
                                <img src={b} alt=""/>
                                    <h1 class="title-w fita">Fitim Radoniqi</h1>
                                    <p className='top'>Marketing and sales</p>
                            </div>
                           
                        </div>

                        <div class="div-a">
                        <div class="info-w as">
                                <i class="fas fa-phone-alt p-w"></i>
                                <a href="tel:+38338775775">+383 38 775 775</a>
                            </div>

                            <div class="info-w as">
                                <i class="fas fa-phone-alt p-w"></i>
                                <a href="tel:+38348519415">+383 48 519 415</a>
                            </div>

                            <div class="info-w as">
                                <div class="fasi  p-w">
                            <img src={viber} /></div>
                                <a href="tel:+38349509928">+383 49 509 928</a>
                            </div>

                            <div class="info-w as">

                                <i class="fas fa-envelope p-w"></i>
                                <a href="mailto:fitim@top-reklama.com">fitim@top-reklama.com</a>
                            </div>
                            <div class="info-w as">
                            <i class="fa fa-location-arrow"></i>
                         <a href="#">Fabrika Fusha e Pejës pn. 30000,Pejë</a>
                         </div>
                            <div class="qrc">

                               <a href='https://top-reklama.com/' class="code">
                                <img src={qr} width='150' height='150' />
                               </a>
                               
                            </div>
                        </div>
                  
                        <button class="save-w aw" onClick={downloadTxtFile}>
                           Save Contact
                            <i class="fas fa-download ml-w "></i>
                        </button>
                    </div>
                </div>
                </div>
                </div>


</>

);
}

export default Fitim;
