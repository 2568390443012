import React from 'react';
import vCardsJS from "vcards-js";
import b from '../images/amin.png'
import c from '../images/instagram (5).png'
import d from '../images/facebook (5).png'
import e from '../images/linkedin (1).png'
import email from'../images/mail.png'
import phone from '../images/call (1).png'
import globe from '../images/globe (1).png'
import user from '../images/add-user (2).png'
import location from '../images/location (1).png'



function Nila(){
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>
  
    var vCard = vCardsJS();
  
    //set properties
    vCard.firstName = "Nila";
    vCard.lastName = "Amini";
    vCard.organization = "AMINI Swiss Prime Estate";
    vCard.title = "Verwaltungsrätin";
    vCard.workPhone = "+41 76 499 58 77";
    vCard.email="nila@amini-spe.ch";
    vCard.url ="www.amini-spe.ch";
    vCard.location="Baarerstrasse 2, CH-6300 Zug";
  
  
    //get as formatted string
    console.log(vCard.getFormattedString());
    const downloadTxtFile = () => {
      const element = document.createElement("a");
      const file = new Blob([vCard.getFormattedString()], {
        type: "text/vcard"
      });
      element.href = URL.createObjectURL(file);
      element.download = "nilaamini.vcf";
      document.body.appendChild(element);
      element.click();
    };
      return (
        <>
<div>
<div class="card" id="more">
<div class="bg-two b-liri b-amin">

            <br/>
          
            <img src={b} class="dive"/>
        </div>
        

        <div class="text perArch amina">
        
        <p className='slogi'></p>
            <h1 class="titles nila">Nila Amini</h1>
                <p class="ceo">Verwaltungsrätin</p>
          
        </div>
     

     <div></div>
        <div class="div-bes div-liri">
                       
                       <div class="info-b info-l">
                           <div class="inf ">
                          <p>Mobile</p></div>
                          <div class="inf inf-amin"><img src={phone} className='icons'/><a href="tel:+41764995877">+41 76 499 58 77</a></div>
                           </div>
                       
                           
                       </div>
                       <div class="div-bes div-liri">
                      
                       <div class="info-b info-l">
                               <p>Email</p>
                               <div class="inf inf-amin">
                              <img src={email} className='icons'/> <a href="mailto:nila@amini-spe.ch">nila@amini-spe.ch</a>
                           </div> </div>
                          
                       </div>
                       <div class="div-bes div-liri">
                      
                       <div class="info-b info-l">
                               <p>Location</p>
                               <div class="inf inf-amin">
                               <img src={location} className='icons'/><a href="https://www.google.com/maps/place/Baarerstrasse+2,+6300+Zug,+Switzerland/@47.1707287,8.5142749,17z/data=!3m1!4b1!4m6!3m5!1s0x479aaaf7ea254c19:0x7919ec00c7654dbd!8m2!3d47.1707251!4d8.5164636!16s%2Fg%2F11cs8h62wt">Baarerstrasse 2, CH-6300 Zug</a>
                           </div></div>
                          
                       </div>
                       <div class="div-bes fundi div-liri ">
                      
                       <div class="info-b info-l ">
                               <p>Website</p>
                               <div class="inf inf-amin">
                               <img src={globe} className='icons'/><a href="https://www.amini-spe.ch/">www.amini-spe.ch</a>
                           </div></div>
                          <div class="inf inf-l useri useri-amin">
                          <button onClick={downloadTxtFile}><img src={user} className='icons'/></button> 
                          </div>
                       
                      </div>

                      <div class="liri-soca">
                      
                     
                             
                              
                              <div className='dolpsoci'>
                            <a href="https://www.instagram.com/amini_swiss_prime_estate/?fbclid=IwAR0G6Q7ewb7keZAB0xlDShnbbZ_y2INjC7DIri-f8wcjtmyiQUBXQ7TKeRc" className='socico'>
                            <img src={c} className="icolit" /></a>
                            <a href="https://www.facebook.com/aminiswissprimeestate" className='socico'>
                            <img src={d}  className="icolit"/></a>
                            <a href="https://www.linkedin.com/company/amini-swiss-prime-estate/?fbclid=IwAR31rhqVgoiBYidjeJteE1n-gaTZx_ABqo8oWSbntr1NekNu0H4_31rHTQ8" className='socico'>
                            <img src={e} className="icolit" /></a>
                         
                   
                          </div> 
                         
                      </div>
                 
                      <div className="copyrighti">
                               <p className='copyright'>© Copyright 2023. <a href="https://dolphinstech.eu/nfcdeutsch.html">Dolphins Group</a></p>
                              

                           
                           
                           </div>
                   </div>
                   </div>



</>

);
}

export default Nila;
